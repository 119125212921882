<template>
  <div>
    <div v-if="loader">
      <img src="../assets/demo3.png" alt="" width="230">
      <img width="200" src="../assets/spinner.svg"/>
      <h3 v-if="redirect_text">Завантаження</h3>
    </div>
    <div v-if="error">
      <img src="../assets/demo3.png" alt="" width="230">
      <img width="200" src="../assets/spinner.svg"/>
      <h3>Завантаження</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexView',
  data() {
    return {
      loader: true,
      redirect_text: false,
      error: false,
    }
  },
  async mounted() {
    const token = window.localStorage.getItem('token');
    const redirect_url = this.$route.query.redirect_url;
    if(!redirect_url) {
      this.loader = false;
      this.error = true;
      return;
    }
    if (token) {
      this.redirect_text = true;
      this.$http.get(`${process.env.VUE_APP_API_URL}/me`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response) => {
        const data = response.data;
        const url = new URL(redirect_url);
        url.searchParams.append('username', data.username);
        url.searchParams.append('first_name', data.first_name);
        url.searchParams.append('last_name', data.last_name);
        url.searchParams.append('country', data.country);
        url.searchParams.append('id', data.id);
        url.searchParams.append('target', data.target);
        url.searchParams.append('token', token);
        setTimeout(() => {
          window.location.href = url.toString();
        }, 1500);
      });
    } else {
      this.$router.push({ name: 'Login', query: { redirect_url: redirect_url}})
    }
  }
}
</script>
