<template>
  <div class="panel">
    <div class="panel-body">
      <div v-if="loader">
        <img src="../assets/demo3.png" alt="" width="230">
        <img width="200" src="../assets/spinner.svg"/>
        <h3 v-if="redirect_text">Вхід</h3>
      </div>
      <div v-else>
        <form v-on:submit="submit">
          <img class="mb-4" src="../assets/demo3.png" alt="" width="230">
          <input type="text" class="form-control" v-bind:class="{'is-invalid': isInvalid}" placeholder="Телефон" v-model="phone">

          <button class="w-100 btn btn-lg btn-dark mt-3" type="submit">Далі</button>
          <p class="mt-5 text-muted">Телефон у форматі +380xxxxxxxxx</p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'IndexView',
  data() {
    return {
      phone: '',
      email: '',
      redirect_url: '',
      loader: true,
      redirect_text: false,
      isInvalid: false,
      empty: false,
    }
  },
  async mounted() {
    const token = window.localStorage.getItem('token');
    const redirect_url = this.$route.query.redirect_url
    if (token) {
      this.empty = true;
      this.redirect_text = true;
      this.$http.get(`${process.env.VUE_APP_API_URL}/me`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response) => {
        const data = response.data;
        const url = new URL(redirect_url);
        url.searchParams.append('username', data.username);
        url.searchParams.append('first_name', data.first_name);
        url.searchParams.append('last_name', data.last_name);
        url.searchParams.append('country', data.country);
        url.searchParams.append('id', data.id);
        url.searchParams.append('target', data.target);
        url.searchParams.append('token', token);
        setTimeout(() => {
          window.location.href = url.toString();
        }, 1500);
      });
    } else {
      this.redirect_url = redirect_url;
      this.loader = false;
    }
  },
  methods: {
    submit(e) {

      e.preventDefault();

      const phonePattern = new RegExp('^\\+(?:[0-9]●?){6,14}[0-9]$');

      this.isInvalid = false;
      if(!this.phone) {
        this.isInvalid = true;
        return;
      }
      if(this.phone.length < 1) {
        this.isInvalid = true;
        return;
      }

      if(!phonePattern.exec(this.phone)) {
        this.isInvalid = true;
        return;
      }

      this.loader = true;
      this.$http.post(`${process.env.VUE_APP_API_URL}/auth/login`, {
        username: this.phone,
        redirect_url: this.redirect_url,
      }).then((response) => {
        const data = response.data;
        const request = data.request;
        const require = request.require;
        const requireCode = require.code;
        const requirePassword = require.password;
        console.log(require);
        if (requireCode) {
          this.$router.push({name: 'Code', params: {request_id: request.id}})
        }
        if (requirePassword) {
          this.$router.push({name: 'Password', params: {request_id: request.id}})
        }
      })
    }
  }
}
</script>
